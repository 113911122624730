"use client"
/* eslint @typescript-eslint/no-unsafe-assignment: 0 */
/* eslint @typescript-eslint/no-unsafe-member-access: 0 */
/* eslint @typescript-eslint/no-unsafe-call: 0 */

import { usePathname, useSearchParams } from "next/navigation"
import { useEffect, useRef } from "react"

export default function Intercom() {
    const pathname = usePathname()
    const searchParams = useSearchParams()
    const bootedRef = useRef(false)

    useEffect(() => {
        const anyWindow = window as any
        if (!bootedRef.current && anyWindow?.Intercom) {
            bootedRef.current = true
            anyWindow.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "uatk7i74",
                // name: user.name, // Full name
                // email: user.email, // the email for your user
                // created_at: user.createdAt, // Signup date as a Unix timestamp
            })
        }

        if (bootedRef.current) {
            anyWindow.Intercom("update")
        }
    }, [pathname, searchParams])

    return null
}
