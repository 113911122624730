"use client"

import { usermavenClient } from "@usermaven/sdk-js"
import { usePathname, useSearchParams } from "next/navigation"
import { useEffect } from "react"

export default function Usermaven() {
    const pathname = usePathname()
    const searchParams = useSearchParams()

    useEffect(() => {
        const usermaven = usermavenClient({
            key: "UMeoLw6PZ3",
            tracking_host: "https://events.usermaven.com",
        })

        void usermaven.track("pageview")
    }, [pathname, searchParams])

    return null
}
