"use client"

import Script from "next/script"
import { PropsWithChildren, useCallback, useState } from "react"
import { HubspotContext } from "./HubspotContext"

export default function HubspotProvider(props: PropsWithChildren) {
    const [loaded, setLoaded] = useState(false)

    const onLoad = useCallback(() => {
        setLoaded(true)
    }, [])

    return (
        <HubspotContext.Provider value={{ loaded }}>
            {props.children}
            <Script
                id="hubspot"
                src="https://js.hsforms.net/forms/v2.js"
                strategy="afterInteractive"
                onLoad={onLoad}
            />
        </HubspotContext.Provider>
    )
}
